import React, { useState, useEffect } from "react";
import axios from 'axios';
import Ellipse1 from "../images/blogbanner.jpg";
import Lets from './Lets';
import { Link } from "react-router-dom";
import { POSTS_WITH_EXCLUDED_CATEGORIES_URL, NOT_FOUND_IMAGE_URL, MEDIA_API_URL, CATEGORIES_API_URL, USERS_API_URL } from '../constants/api';
const Blog = ({ postId }) => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(POSTS_WITH_EXCLUDED_CATEGORIES_URL,);
        const champData = Object.values(response.data);
        const postsWithMedia = await Promise.all(
          champData.map(async (employee) => {
            const media_id = employee.featured_media;
            if (media_id === 0) {
              return { ...employee, mediaItem: { source_url: NOT_FOUND_IMAGE_URL } };
            } else {
              try {
                const featuredResponse = await axios.get(`${MEDIA_API_URL}/${media_id}`);
                const mediaItem = featuredResponse.data;
                return { ...employee, mediaItem };
              } catch (mediaError) {
                console.error(`Error fetching media for post ${employee.id}:`, mediaError);
                return { ...employee, mediaItem: { source_url: NOT_FOUND_IMAGE_URL } };
              }
            }
          })
        );
        setPosts(postsWithMedia);

        const authorsResponse = await axios.get(USERS_API_URL);
        const authorsData = authorsResponse.data.reduce((acc, author) => {
          acc[author.id] = author.name;
          return acc;
        }, {});
        setAuthors(authorsData);

        const categoriesResponse = await axios.get(CATEGORIES_API_URL);
        const excludedCategoryID = 8;
        const categoriesData = categoriesResponse.data.reduce((acc, category) => {

          // acc[category.id] = category.name;
          // return acc;
          if (category.id !== excludedCategoryID) {
            acc[category.id] = category.name;
          }
          return acc;
        }, {});
        setCategories(categoriesData);

      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    window.scrollTo(0, 0);
    fetchPosts();
  }, []);
  useEffect(() => {
    // Filter posts based on the search query
    const filtered = posts.filter(post => post.title.rendered.toLowerCase().includes(searchQuery.toLowerCase()));
    setFilteredPosts(filtered);
  }, [searchQuery, posts]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const getFirstWords = (content, wordCount) => {
    const strippedContent = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const words = strippedContent.split(/\s+/); // Split the text into words
    const firstWords = words.slice(0, wordCount).join(' '); // Take the first N words and join them
    return `${firstWords}...`; // Add ellipsis or modify as needed
  };
  const indexOfLastPost =currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = (searchQuery ? filteredPosts : posts.filter(post => post.categories != null && Number(post.categories) !== 8));
  const paginate = pageNumber => {
    // Prevent navigating to a non-existing page
    if (pageNumber > 0 && pageNumber <= Math.ceil(currentPosts.length / postsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  return (
    <>
      <section className="passion">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Our Blog</h1>
              <img src={Ellipse1} alt="Ellipse1" />
            </div>
          </div>
        </div>
      </section>
      <section className="blogbox">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
            {currentPosts.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage).map(post => (
                  <div className="blogdata" key={post.id}>
                    <div className="blogimg">
                      <Link to={`/Blogs/${post.slug}`} className="blogbgremove">
                        {post.mediaItem && <img key={post.mediaItem.id} src={post.mediaItem.source_url} alt="blogpost" />}
                        <span>{categories[post.categories]}</span>
                      </Link>
                    </div>
                    <div className="blogtext">
                      <Link to={`/Blogs/${post.slug}`}>
                        <ul>
                          <li>On <span> {new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span></li>
                          <li>By <span> {authors[post.author]}</span></li>
                        </ul>
                        <h6>{post.title.rendered}</h6>
                        <p dangerouslySetInnerHTML={{ __html: getFirstWords(post.content.rendered, 20) }} />
                        <Link className="bloglink" to={`/Blogs/${post.slug}`}>Explore More</Link>
                      </Link>
                    </div>

                  </div>

                ))
              }
              {currentPosts.length > postsPerPage && (
                <ul className="pagination">
                  {Array.from({ length: Math.ceil(currentPosts.length / postsPerPage) }, (_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="col-md-4">
              <div className="blogsidebar">
                <form>
                  <input type="text" name="" placeholder="Enter Keyword..." value={searchQuery} onChange={handleSearchChange} />
                </form>
              </div>

              <div className="blogsidebar">
                <h5 className="text-center">Categories</h5>
                <ul>
                  {posts.filter(post => {
                    return Number(post.categories) !== 8; // Convert to number before comparison
                  }).map((post, i) => {
                    return (
                      <li key={i}><Link to={`/Blogs/${post.slug}`}>{categories[post.categories]}</Link></li>
                    )
                  })}
                </ul>
              </div>

              <div className="blogsidebar">
                <h5 className="text-center">Popular Post</h5>

                {posts.filter(post => {
                  return Number(post.categories) !== 8; // Convert to number before comparison
                }).map((post, i) => (
                  <Link to={`/Blogs/${post.slug}`} key={i} className="blogpop">
                    {post.mediaItem && <img key={post.mediaItem.id} src={post.mediaItem.source_url} alt="blogpost" />}
                    <span>{categories[post.categories]}</span>
                    <p dangerouslySetInnerHTML={{ __html: getFirstWords(post.content.rendered, 10) }} />
                  </Link>
                ))}

              </div>

            </div>
          </div>
        </div>
      </section>

      <Lets />

    </>
  );
};

export default Blog;